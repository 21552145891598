/* eslint-disable no-case-declarations */
/**
 * CAUTION: This reducer file is used between both app-store-v2 and collection-store
 * YOU SHOULD TAKE CARE TO MAKE SURE BOTH SIDES WORK WHEN REMOVING THINGS HERE
 */

import { combineReducers } from "redux"
import { createResponsiveStateReducer } from "redux-responsive"

import applyFilters from "helpers/collection/applyFilters"

// List of actions to check and remove duplicated items
// const actionsToCheckDuplicatedItems = [
//   "APPEND_PRODUCT_RESULT",
//   "PREPEND_PRODUCT_RESULT",
// ]

function collection(
  // Commented out for temp state
  state = {
    banners: null,
    breadcrumb: [],
    description: "",
    experiment_variation: "",
    filter_menu: {},
    filtered_price_range: { max: null, min: null },
    gridView: "small",
    long_subtitle: "",
    model: "",
    mountedPath: "",
    pagination: {},
    placeholders: 0,
    preserve_cat_path: "",
    product_result: [],
    seo_description: "",
    seo_title: "",
    sort_menu: {},
    subtitle: null,
    title: "",
  },
  action
) {
  let newState = Object.assign({}, state)
  let filters =
    state?.filter_menu && state?.filter_menu.all_collection_filters
      ? [...state.filter_menu.all_collection_filters]
      : []

  // Temporary disable
  // Check and remove duplicated items from action.productResult
  // if (actionsToCheckDuplicatedItems.includes(action.type)) {
  //   const availableItemsURLs = new Set(
  //     state.product_result.map((item) => {
  //       if (item.item_type == "ad_block") {
  //         return item.href
  //       } else {
  //         const default_look =
  //           item.looks[item.default_look] ||
  //           item.looks[Object.keys(item.looks)[0]]

  //         return default_look.product_url
  //       }
  //     })
  //   )
  //   action.productResult = action.productResult.filter((item) => {
  //     if (item.item_type == "ad_block") {
  //       return true
  //     }
  //     const default_look =
  //       item.looks[item.default_look] || item.looks[Object.keys(item.looks)[0]]
  //     const item_url = default_look.product_url
  //     if (availableItemsURLs.has(item_url)) {
  //       return false
  //     }
  //     availableItemsURLs.add(item_url)
  //     return true
  //   })
  // }
  // END

  switch (action.type) {
    case "LOAD_REACT_COLLECTION":
      if (!window.location.search.includes("switchMode")) {
        newState.model = "clear"
      }
      return newState
    case "SET_COLLECTION":
      newState.banners = action.collection.banners
      newState.debug = action.collection.debug
      newState.carousel = action.collection.carousel
      newState.description = action.collection.description
      newState.experiment_variation = action.collection.experiment_variation
      newState.filter_menu = {
        ...state.filter_menu,
        ...action.collection.filter_menu,
      }
      newState.filtered_price_range = {
        ...state.filtered_price_range,
        ...action.collection.filtered_price_range,
      }
      newState.mountedPath = `/${action.collection.pagination.current_page}`

      newState.pagination = {
        ...state.pagination,
        ...action.collection.pagination,
      }
      newState.preserve_cat_path = action.collection.preserve_cat_path
      newState.seo_description = action.collection.seo_description
      newState.seo_title = action.collection.seo_title
      newState.sort_menu = {
        ...state.sort_menu,
        ...action.collection.sort_menu,
      }
      newState.subtitle = action.collection.subtitle
      newState.super_filters = action.collection.super_filters
      newState.title = action.collection.title
      newState.long_subtitle = action.collection.long_subtitle
      newState.breadcrumb = action.collection.breadcrumb
      return newState
    case "SET_PRODUCT_RESULT":
      newState.product_result = [...action.productResult]
      if (newState.placeholders > 0) {
        newState.placeholders = Math.max(newState.placeholders - 30, 0)
      }
      return newState

    case "REPLACE_COLLECTION_DATA":
      Object.keys(action.data).forEach((key) => {
        newState[key] = action.data[key]
      })
      return newState
    case "SET_GRID_VIEW":
      newState.gridView = action.view
      return newState
    case "SET_PRICE_RANGE":
      newState.filtered_price_range = { max: action.max, min: action.min }
      return newState
    case "UPDATE_PRICE_RANGE":
      newState.filtered_price_range = { max: action.max, min: action.min }
      return newState
    case "UPDATE_FILTER":
      applyFilters(
        action.filter,
        action.belongs_to,
        filters,
        state.experiment_variation
      )
      newState.filter_menu = {
        ...state.filter_menu,
        all_collection_filters: filters,
      }
      return newState
    case "UPDATE_UNIQUE_FILTER":
      applyFilters(
        action.param.filter,
        action.param.belongs_to,
        filters,
        state.experiment_variation
      )
      newState.filter_menu = {
        ...state.filter_menu,
        all_collection_filters: filters,
      }
      return newState
    case "APPEND_PRODUCT_RESULT":
      newState.product_result = [
        ...state.product_result,
        ...action.productResult,
      ]
      return newState
    case "PREPEND_COLLECTION_PLACEHOLDERS":
      newState.placeholders += action.numPages * 30
      return newState
    case "PREPEND_PRODUCT_RESULT":
      newState.product_result = [
        ...(action.productResult || []),
        ...state.product_result,
      ]
      if (newState.placeholders > 0) {
        newState.placeholders = Math.max(newState.placeholders - 30, 0)
      }
      return newState
    case "CLEAR_CATEGORY_FILTERS":
      filters.forEach((filter) => {
        if (action.category === "all" || action.category === filter.name) {
          let categories = filter.categories.map((category) => {
            return {
              ...category,
              is_active_filter: false,
            }
          })
          filter.categories = [...categories]
        }
      })
      newState.filter_menu = {
        ...state.filter_menu,
        all_collection_filters: filters,
      }
      return newState
    case "UPDATE_CURRENT_PAGE":
      newState.pagination = {
        ...state.pagination,
        current_page_number: action.page,
      }
      return newState
    case "UPDATE_CURRENT_SORT":
      newState.sort_menu = {
        ...state.sort_menu,
        current_sort: action.option,
      }
      return newState

    case "RELOAD_COLLECTION":
      newState.pagination = {
        ...state.pagination,
        current_page_number: action.query.page
          ? parseInt(action.query.page)
          : state.pagination.current_page_number,
      }
      newState.sort_menu = {
        ...state.sort_menu,
        current_sort: action.query.sort
          ? action.query.sort
          : state.sort_menu.current_sort,
      }
      let new_filter_tags = action.query["filtered_tags[]"]
      let filter_options = [...state.filter_menu.all_collection_filters]
      filter_options.forEach((filter) => {
        let categories = filter.categories.map((category) => {
          var is_active = false
          if (typeof new_filter_tags == "object") {
            new_filter_tags.forEach((new_filter) => {
              if (category.filter_param[0] === new_filter) {
                is_active = true
              }
            })
          } else if (typeof new_filter_tags == "string") {
            is_active = category.filter_param[0] === new_filter_tags
          } else if (!new_filter_tags) {
            is_active = false
          }
          return {
            ...category,
            is_active_filter: is_active,
          }
        })
        filter.categories = [...categories]
      })
      newState.filter_menu = {
        ...state.filter_menu,
        all_collection_filters: filter_options,
      }
      return newState
    default:
      return state
  }
}

const collectionReducer = combineReducers({
  browser: createResponsiveStateReducer({
    desktop: 9999,
    mobile: 767,
    tablet: 1023,
  }),
  collection,
})

export { collectionReducer }

export default collection
