import { connectRouter } from "connected-react-router"
import { combineReducers } from "redux"
import { createResponsiveStateReducer } from "redux-responsive"

import {
  ADD_ALERT,
  REMOVE_ALERT,
  SET_MESSAGE,
  SET_EXPERIMENT,
  SET_LOGIN_NOTIFICATION,
  ACTIVATE_MENU,
  DEACTIVATE_MENU,
  ACTIVATE_ACCOUNT_MENU,
  DEACTIVATE_ACCOUNT_MENU,
  ACTIVATE_SUBMENU,
  DEACTIVATE_SUBMENU,
  SHOW_SUBCATEGORY,
  SHOW_INFO_INDEX,
  UPDATE_SEARCH_VALUE,
  MAY_UPDATE_SEARCH_SUGGESTIONS,
  SET_EXPLICIT_LOGIN,
  DO_LOGIN,
  DO_LOGOUT,
  CLEAR_LOGIN_CALLBACK,
  SET_USER_DATA,
  CLEAR_SEARCH_SUGGESTIONS,
  CLEAR_SEARCH_USER_INPUT,
  FACEBOOK_LOGIN,
  SET_DESKTOP_SUBMENU,
  SET_FACEBOOK_LOGIN_STATUS,
  SET_FACEBOOK_LOGIN_PROCESS_STATE,
  SET_MOBILE_SUBMENU,
  SET_POINTS_INFO,
  SET_USER_ADDRESSES,
  UPDATE_POINTS_ACCOUNT,
  SET_REFERRAL_NAME,
  SHOW_LOCALE_HM,
  HIDE_LOCALE_HM,
  SET_TARGET_COUNTRY,
  SET_TARGET_LOCALE,
  ACTIVATE_HELP_BAR,
  DEACTIVATE_HELP_BAR,
  TOGGLE_COUNTRY_MODAL,
  SET_FORM_PAUSE,
} from "./actions"

function menu(
  state = {
    desktopSubmenu: "",
    helpBarShowing: true,
    infoMenuIndex: null,
    mobileSubmenu: "",
    showAccountMenu: false,
    showMenu: false,
    showSubmenu: false,
    subcategoryIndex: 0,
  },
  action
) {
  let newState = Object.assign({}, state)
  switch (action.type) {
    case SET_MOBILE_SUBMENU:
      newState.mobileSubmenu = action.submenu
      return newState
    case SET_DESKTOP_SUBMENU:
      newState.desktopSubmenu = action.submenu
      return newState
    case SHOW_SUBCATEGORY:
      newState.subcategoryIndex = action.index
      return newState
    case SHOW_INFO_INDEX:
      newState.infoMenuIndex =
        action.index === state.infoMenuIndex ? null : action.index
      return newState
    case ACTIVATE_MENU:
      newState.showMenu = true
      return newState
    case DEACTIVATE_MENU:
      newState.showMenu = false
      return newState
    case ACTIVATE_ACCOUNT_MENU:
      newState.showAccountMenu = true
      return newState
    case DEACTIVATE_ACCOUNT_MENU:
      newState.showAccountMenu = false
      return newState
    case ACTIVATE_SUBMENU:
      newState.showSubmenu = true
      return newState
    case DEACTIVATE_SUBMENU:
      newState.showSubmenu = false
      return newState
    case ACTIVATE_HELP_BAR:
      newState.helpBarShowing = true
      return newState
    case DEACTIVATE_HELP_BAR:
      newState.helpBarShowing = false
      return newState
    default:
      return state
  }
}

function messages(
  state = {
    provideUserInfo: {},
  },
  action
) {
  let newState = Object.assign({}, state)
  switch (action.type) {
    case SET_MESSAGE:
      newState[action.key] = { type: action.messageType, value: action.value }
      return newState
    default:
      return state
  }
}

function header(
  state = {
    dropdown: "",
    search: { suggestions: [], value: "" },
  },
  action
) {
  let newState = Object.assign({}, state)
  switch (action.type) {
    case UPDATE_SEARCH_VALUE:
      newState.search.value = action.value
      return newState
    case MAY_UPDATE_SEARCH_SUGGESTIONS:
      newState.search.suggestions = action.suggestions
      return newState
    case CLEAR_SEARCH_SUGGESTIONS:
      newState.search.suggestions = []
      return newState
    case CLEAR_SEARCH_USER_INPUT:
      newState.search.value = ""
      return newState
    default:
      return state
  }
}

function user(
  state = {
    callback: false,
    explicitLogin: false,
    facebook: {
      loginState: "not-connected",
      status: null,
    },
    jewlrorders: {},
    loaded: false,
    loginMessage: null,
    pauseForm: false,
    pointsAccount: {},
    referralName: null,
  },
  action
) {
  let newState = Object.assign({}, state)
  switch (action.type) {
    case CLEAR_LOGIN_CALLBACK:
      newState.callback = false
      return newState
    case FACEBOOK_LOGIN:
      newState.callback = action.callback
      return newState
    case SET_EXPLICIT_LOGIN:
      newState.explicitLogin = true
      return newState
    case DO_LOGIN:
      newState.loaded = true
      newState.jewlrorders = action.loginObj.jewlrorders.user
      newState.pointsAccount = action.loginObj.jewlrorders.user.points_info
      return newState
    case DO_LOGOUT:
      newState.loaded = false
      newState.jewlrorders = {}
      newState.pointsAccount = {}
      return newState
    case SET_USER_ADDRESSES:
      newState.jewlrorders.addresses = action.data
      return newState
    case SET_USER_DATA:
      newState.loaded = true
      newState.jewlrorders = action.userObj || {}
      newState.pointsAccount =
        action?.userObj?.points_info || action?.userObj?.user?.points_info || {}
      return newState
    case SET_FACEBOOK_LOGIN_STATUS:
      newState.facebook.status = action.response
      return newState
    case SET_FACEBOOK_LOGIN_PROCESS_STATE:
      newState.facebook.loginState = action.status
      return newState
    case SET_POINTS_INFO:
      newState.pointsAccount = action.info
      return newState
    case UPDATE_POINTS_ACCOUNT:
      newState.pointsAccount = Object.assign(
        {},
        state.pointsAccount,
        action.account
      )
      return newState
    case SET_REFERRAL_NAME:
      newState.referralName = action.referralName
      return newState
    case SET_LOGIN_NOTIFICATION:
      newState.loginMessage = action.data
      return newState
    case SET_FORM_PAUSE:
      newState.pauseForm = action.bool
      return newState
    default:
      return state
  }
}

function localeHM(
  state = {
    country: "",
    display: false,
    locale: "",
    showCountryModal: false,
  },
  action
) {
  let newState = Object.assign({}, state)
  switch (action.type) {
    case SHOW_LOCALE_HM:
      newState.display = true
      return newState
    case HIDE_LOCALE_HM:
      newState.display = false
      return newState
    case SET_TARGET_COUNTRY:
      newState.country = action.data
      return newState
    case SET_TARGET_LOCALE:
      newState.locale = action.data
      return newState
    case TOGGLE_COUNTRY_MODAL:
      newState.showCountryModal = action.toggle
      return newState
    default:
      return state
  }
}

const experimentInitialState = () => {
  return {
    homepageBanner: { variant_id: 0 },
    jwln0300YGBadge: { variant_id: 0 },
    pdpViewIn3D: { variant_id: 0 },
    upgradedShipping: { variant_id: 0 },
  }
}

const experiment = (history) => (
  state = experimentInitialState(history),
  action
) => {
  let newState = Object.assign({}, state)
  switch (action.type) {
    case SET_EXPERIMENT:
      newState = { ...state, ...action.experiment }
      return newState
    default:
      return state
  }
}

function alerts(state = [], action) {
  let newState = []
  switch (action.type) {
    case ADD_ALERT:
      newState = [
        ...state,
        {
          id: +new Date(),
          ...action.alert,
        },
      ]
      return newState
    case REMOVE_ALERT:
      return state.filter((alert) => alert.id !== action.id)
    default:
      return state
  }
}

function sale(state = {}, action) {
  let newState = Object.assign({}, state)
  switch (action.type) {
    case "SET_SALE":
      newState = { ...state, ...action.saleData }
      return newState
    default:
      return state
  }
}

function searchSuggestion(state = {}) {
  return state
}

const appReducer = (history) =>
  combineReducers({
    alerts,
    browser: createResponsiveStateReducer({
      desktop: 9999,
      mobile: 767,
      tablet: 1023,
    }),
    experiment,
    header,
    localeHM,
    menu,
    messages,
    router: connectRouter(history),
    sale,
    searchSuggestion,
    user,
  })

export {
  alerts,
  experiment,
  experimentInitialState,
  header,
  menu,
  messages,
  localeHM,
  sale,
  searchSuggestion,
  user,
}

export default appReducer
