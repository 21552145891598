export const getRequiredMessages = (state) => {
  const choices = state.choices || {}
  const wizardData = state.wizard && state.wizard.data ? state.wizard.data : {}
  let required = {}
  let imgRequired = {}
  Object.keys(choices).map((choice_name) => {
    const choice = choices[choice_name]
    if (
      choice.disabled ||
      (choice_name[0] == "e" &&
        choice_name != "ef" &&
        choice_name != "ef2" &&
        wizardData[choice_name] &&
        wizardData[choice_name].min_chars <= 0) ||
      choice_name == "box" ||
      choice_name == "a1" ||
      choice_name == "a2" ||
      choice_name.indexOf("d_") !== -1
    ) {
      return
    }
    if (choice.value && choice.value !== "") {
      if (/img[0-9]+/.test(choice_name)) imgRequired[choice_name] = true
      return
    }

    let missing_msg = choice.missing_msg

    if (
      !missing_msg &&
      wizardData[choice_name] &&
      wizardData[choice_name].missing_msg
    ) {
      missing_msg = wizardData[choice_name].missing_msg
    }

    required[choice_name] = missing_msg
  })

  // NOTE: We check if one of the image wizard is uploaded, then make the rest optional
  // e.g., Photo locket - JWLP0667
  if (Object.keys(imgRequired).length) {
    for (let choice_name in required) {
      if (/img[0-9]+/.test(choice_name)) delete required[choice_name]
    }
  }

  return required
}

export const toFormObject = (state) => {
  const choices = state.choices || {}
  const lid = state.wizardUI.lid ? state.wizardUI.lid : ""
  let formObj = {
    experiment: state.experiment,
    line_item_id: lid,
  }
  Object.keys(choices).map((choice_name) => {
    const choice = choices[choice_name]
    if (!choice.disabled) {
      formObj[choice_name] = choice.is_default ? "" : choice.value || ""
      _appendImgThresholdToForm(choice_name, choice, formObj)
    }
  })
  return formObj
}

const _appendImgThresholdToForm = (choice_name, choice, formObj) => {
  // TODO: separate this logic
  if (/img[0-9]+/.test(choice_name) && choice.threshold_value) {
    formObj[`${choice_name}_th`] = choice.threshold_value
  }
}
