import { Expand } from "@jewlr/storybook/animate"
import { Button, Typography } from "@jewlr/storybook/core"
import { AnimatePresence } from "framer-motion"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import { hideScrollbar } from "styles/utils"

export const MenuTriangle = styled.div`
  display: block;
  ${(props) =>
    props.show &&
    `
    :hover {
      color: ${props.theme.colors.primary};
      font-weight: 500;
    }
    :before,
    :after {
      position: absolute;
      display: block;
      content: " ";
      border: 9px solid transparent;
      width: 0;
      height: 0;
    }
    :before {
      bottom: -2px;
      z-index: ${props.theme.zIndices.header + 7};
      border-bottom-color: ${props.theme.colors.white};
      ${props.theme.mediaQueries.tablet`
        bottom: -14px;
      `}
    }
    :after {
      bottom: 0;
      border-bottom-color: ${props.theme.colors.grey[10]};
      z-index: ${props.theme.zIndices.header + 1};
      ${props.theme.mediaQueries.tablet`
        bottom: -12px;
      `}
    }
  `}
`

const StyledDropdown = styled(Expand)`
  background-color: white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  position: absolute;
  right: 12px;
  top: 58px;
  width: 315px;
  z-index: 3;
  ${(props) => props.theme.mediaQueries.tablet`
    right: 0;
    top: auto;
  `}
  ${(props) =>
    props.show &&
    `
    border: 1px solid #ebebeb;
    ${props.theme.mediaQueries.tablet`
      margin-top: 12px;
    `}
  `}
`

const variants = {
  hide: {
    height: 0,
    transition: { ease: "easeOut" },
    transitionEnd: { display: "none" },
  },
  show: {
    display: "block",
    height: "auto",
    transition: { ease: "easeOut" },
  },
}

export const Dropdown = ({ children, dataCy, show, ...props }) => (
  <AnimatePresence>
    {show && (
      <StyledDropdown
        animate="show"
        data-cy={dataCy}
        exit="hide"
        initial="hide"
        show
        variants={variants}
        {...props}
      >
        {children}
      </StyledDropdown>
    )}
  </AnimatePresence>
)

Dropdown.propTypes = {
  children: PropTypes.element,
  dataCy: PropTypes.string,
  show: PropTypes.bool,
}

export const DropdownScrollable = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.grey[15]};
  max-height: 260px;
  overflow-x: hidden;
  overflow-y: auto;
  ${hideScrollbar}
  @media (max-width: 321px) {
    max-height: 180px;
  }
`
export const DropdownTitle = styled(Typography).attrs((props) => ({
  as: "h3",
  ...props,
}))`
  border-bottom-color: ${(props) => props.theme.colors.grey[15]};
  border-bottom-width: 1px;
  border-bottom-style: solid;
  font-size: 16px;
  padding-bottom: 16px;
`

export const Cta = styled(Button).attrs((props) => ({
  outline: "black",
  ...props,
}))`
  font-size: 14px;
  padding: 8px;
  width: 100%;
`

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`
