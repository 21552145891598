/**
 * Check if value passes filter validation
 *
 * @param {Object} filter
 * @param {string} selectedValue
 */
function filterCheck(filter, selectedValue) {
  /**
   * In case if filter does not have 'type' property it means that this is just a string values
   */
  if (filter && filter.hasOwnProperty("type")) {
    switch (filter.type) {
      case "exact":
        return filter.value === selectedValue
      case "regex":
        return RegExp(filter.value).test(selectedValue)
    }
  }
  return filter === selectedValue
}

/**
 * e.g.
 * 1. productOptions => [{
 *      ...
 *      filters: {
 *          ...
 *          sku: [] <- selectionProperties.pref = 'sku'
 *          ...
 *      }
 * }]
 * 2. Look for ProductOption that matches filter
 *
 *
 * selectionProperties eg {pref: 'sku', value: '14KW'}
 * pref defines which filter property we use,
 * value defines what we are looking for
 *
 * @param {Array} productOptions Array of ProductOption
 * @param {Object} selectionProperties Object defines selection
 * @param {Boolean} defaultIfNoFilters if option has no filters property default
 * @returns {Array} filteredProductOptions
 */
export function filterProductOptions(
  productOptions,
  selectionProperties,
  defaultIfNoFilters = false
) {
  let filteredProductOptions = productOptions.filter((productOption) => {
    let match = true

    if (
      productOption.filters &&
      productOption.filters.hasOwnProperty(selectionProperties.pref)
    ) {
      let filterOptions = productOption.filters[selectionProperties.pref]
      match = filterMatch(filterOptions, selectionProperties.value)
    } else if (productOption.filters) {
      Object.keys(productOption.filters).every((pref) => {
        if (selectionProperties[pref]) {
          let filterOptions = productOption.filters[pref]

          match = filterMatch(filterOptions, selectionProperties[pref].value)
        } else {
          match = defaultIfNoFilters
        }
        return match
      })
    } else {
      match = defaultIfNoFilters
    }
    return match
  })
  return filteredProductOptions
}

function filterMatch(options, propertyValue) {
  let index = 0
  let match = false
  while (index < options.length && !match) {
    const filter = options[index]
    match = filterCheck(filter, propertyValue)
    ++index
  }
  return match
}

export function filterProductOptionsV2(
  productOptions,
  choices = {},
  defaultIfNoFilters = false
) {
  const multipleFilters = []
  Object.keys(choices).map((key) => {
    multipleFilters.push({
      pref: key,
      value: choices[key].value,
    })
  })
  let filteredProductOptions = productOptions.filter((productOption) => {
    if (multipleFilters.length) {
      let match = true
      Object.keys(productOption.filters).map((key) => {
        let match2 = true
        multipleFilters.forEach((selectionProperty) => {
          if (
            selectionProperty.pref == key &&
            !filterMatch(productOption.filters[key], selectionProperty.value)
          ) {
            match2 = false
          }
        })
        if (!match2) match = false
      })
      return match
    } else {
      return defaultIfNoFilters
    }
  })
  return filteredProductOptions
}

/**
 * Check if metal is in array
 * @param {array} metalFilters
 * @param {string} metalCode
 */
export function checkMetalExists(metalFilters, metalCode) {
  let found = false
  if (metalFilters) {
    metalFilters.forEach((metalFilter) => {
      if (filterCheck(metalFilter, metalCode)) {
        found = true
      }
    })
  } else {
    return true
  }
  return found
}

export function findMathesInFilters(filters1, filters2, pref) {
  var matchFound = false
  filters1[pref]?.forEach((filter1Value) => {
    filters2[pref]?.forEach((filter2Value) => {
      if (filterCheck(filter1Value, filter2Value.value)) {
        matchFound = true
      }
    })
  })
  return matchFound
}

export function getSelectedMetalFilter(choices) {
  if (typeof choices.sku === "undefined" && typeof choices.m2 === "undefined") {
    return ""
  } else {
    // If m2 is defined, assume that is the metal filter trigger
    return {
      pref: typeof choices.m2 === "undefined" ? "sku" : "m2",
      value:
        typeof choices.m2 === "undefined"
          ? choices.sku.value
          : choices.m2.value,
    }
  }
}
