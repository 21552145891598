import { MediaBox } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import { withRouter } from "react-router-dom"
import styled from "styled-components"

import MobileHeader from "./mobile-header"

const MobileContainer = styled(MediaBox).attrs({
  lessThan: "desktop",
})`
  position: relative;
`
const DesktopHeaderContainer = styled(MediaBox).attrs({
  greaterThanOrEqual: "desktop",
})`
  opacity: 0;
  ${(props) => props.theme.mediaQueries.desktop`
    height: 112px;
  `}
`

const PushContainer = styled.div`
  opacity: 0;
  ${(props) =>
    props.inCart &&
    `
    display: block;
    ${props.theme.mediaQueries.tablet`
      display: none;
    `}
  `}
`

const HeaderPush = ({ location }) => {
  const inCart = location.pathname.includes("cart")

  return (
    <PushContainer aria-hidden inCart={inCart}>
      <MobileContainer>
        <MobileHeader empty />
      </MobileContainer>
      <DesktopHeaderContainer />
    </PushContainer>
  )
}

HeaderPush.propTypes = {
  location: PropTypes.object,
}

export default withRouter(HeaderPush)
