import { faArrowRightLong } from "@jewlr/pro-regular-svg-icons/faArrowRightLong"
import {
  Box,
  Flex,
  FontAwesomeIcon,
  Image,
  MediaBox,
  Typography,
} from "@jewlr/storybook/core"
import { motion } from "framer-motion"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import styled from "styled-components"

import { jewlr_t } from "helpers/application"

import Clock from "./clock"

const fontFace = (fontFamily, src, fontWeight) => `
  @font-face {
    font-family: '${fontFamily}';
    src: url('${require(`fonts/${src}.ttf`)}') format('truetype'),
         local("Helvetica"), local("Arial");
    font-weight: ${fontWeight};
    font-style: normal;
    font-display: swap;
  }
`

const Container = styled(MediaBox).attrs((props) => ({
  bg: "black",
  color: "white",
  height: { _: "48px", desktop: "50px" },
  ...props,
}))`
  @media screen and (max-width: 1023px) {
    ${fontFace("Montserrat", "montserrat/Montserrat-Regular", "normal")}
    ${fontFace("Montserrat", "montserrat/Montserrat-SemiBold", "600")}
    ${fontFace("Montserrat", "montserrat/Montserrat-Bold", "700")}

    font-family: "Montserrat", "FunctionPro", sans-serif;
  }
`

const Wrapper = styled(Flex).attrs((props) => ({
  alignItems: "center",
  containWide: true,
  height: "100%",
  justifyContent: { _: "center", desktop: "space-between" },
  pad: true,
  textAlign: "center",
  width: "100%",
  ...props,
}))``

const List = styled.ul`
  font-size: 16px;
  line-height: 12px;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    border-left: 1px solid
      ${(props) => props.theme.colors[props.color] || props.theme.colors.white};
    display: inline-block;
    padding: 0 6px;
  }

  li:first-child {
    border: none;
  }
`

const Banner = styled(Typography).attrs((props) => ({
  as: "span",
  fontSize: { _: "14px", desktop: "16px" },
  fontWeight: { _: "600", desktop: "700" },
  letterSpacing: "-0.02em",
  ...props,
}))``

const Delivery = styled(Box)`
  align-items: center;
  display: flex;
  justify-content: center;
`

const textVariants = {
  hidden: {
    opacity: 0,
    transitionEnd: {
      display: "none",
    },
  },
  visible: {
    display: "block",
    opacity: 1,
    transition: { delay: 0.5 },
  },
}

const TextRoller = ({ color, currentIndex, id, texts }) => {
  return texts.map((text, i) => (
    <motion.div
      animate={currentIndex === i ? "visible" : "hidden"}
      initial="hidden"
      key={`microbanner-${i}-${id}`}
      variants={textVariants}
    >
      {text.type === "countdown" ? (
        <Clock color={color} text={text} />
      ) : text.type === "delivery" ? (
        <Delivery
          as={text.url ? Link : "div"}
          data-lc="countdown-banner"
          to={text.url}
        >
          <Image
            alt={`Fast ${jewlr_t("terms.Shipping")}`}
            height="15px"
            mr="10px"
            src={require("images/layout/header/fast-shipping.svg")}
            width="26px"
          />
          <Banner
            dangerouslySetInnerHTML={{ __html: text.text }}
            textAlign="left"
            {...text.style}
          />
        </Delivery>
      ) : (
        <Box
          as={text.url ? Link : "div"}
          data-lc="countdown-banner"
          to={text.url}
        >
          <Flex alignItems="center">
            <Banner
              dangerouslySetInnerHTML={{
                __html: text.text,
              }}
              {...text.style}
            />
            <FontAwesomeIcon
              color={color}
              fontSize="16px"
              icon={faArrowRightLong}
              ml="10px"
            />
          </Flex>
        </Box>
      )}
    </motion.div>
  ))
}

const CountdownBanner = ({ microbanner }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  let desktopTexts = microbanner.desktop_texts
  let mobileTexts = microbanner.mobile_texts

  useEffect(() => {
    if (desktopTexts.length > 0) {
      const interval = setInterval(() => {
        setCurrentIndex(
          (currentIndex) => (currentIndex + 1) % desktopTexts.length
        )
      }, 5000)
      return () => clearInterval(interval)
    }
  }, [])

  const { color } = microbanner.style || {}

  return (
    <>
      <Container {...microbanner.style} lessThan="desktop" px={2} width="100%">
        <Wrapper>
          <TextRoller
            color={color}
            currentIndex={currentIndex}
            id="mobile"
            texts={mobileTexts}
          />
        </Wrapper>
      </Container>
      <Container {...microbanner.style} greaterThanOrEqual="desktop">
        <Wrapper>
          <Flex alignItems="center">
            <Image
              alt={`Fast ${jewlr_t("terms.Shipping")}`}
              aria-hidden
              height="12px"
              src={
                color === "black"
                  ? require("images/layout/header/fast-shipping-black.svg")
                  : require("images/layout/header/fast-shipping.svg")
              }
              width="20px"
            />
            <List color={color}>
              <li>
                <Link to="/info/shipping_information">
                  FREE {jewlr_t("terms.Shipping")}
                </Link>
              </li>
              <li>
                <Link to="/info/returns">99-Day Returns</Link>
              </li>
              <li>
                <Link to="/info/warranty-information">One-Year Warranty</Link>
              </li>
            </List>
          </Flex>
          <Box>
            <TextRoller
              color={color}
              currentIndex={currentIndex}
              id="desktop"
              texts={desktopTexts}
            />
          </Box>
        </Wrapper>
      </Container>
    </>
  )
}

CountdownBanner.propTypes = {
  microbanner: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    microbanner: state.sale.microbanner,
  }
}

export default connect(mapStateToProps)(CountdownBanner)
