import { mediaStyle } from "@jewlr/storybook/core"
import { createGlobalStyle } from "styled-components"

import normalize from "styles/normalize"

const fontFace = (fontFamily, src, fontWeight, fontStyle) => {
  return `
    @font-face {
      font-family: '${fontFamily}';
      src: url('https://assets.jewlr.com/fonts/${src}.woff2') format('woff2'),
           url('https://assets.jewlr.com/fonts/${src}.woff') format('woff'),
           url('https://assets.jewlr.com/fonts/${src}.ttf') format('truetype'),
           local("Helvetica"), local("Arial");
      font-weight: ${fontWeight};
      font-style: ${fontStyle};
      font-display: swap;
    }
  `
}

export const GlobalStyle = createGlobalStyle`
  ${fontFace(
    "FunctionPro",
    "functionpro/functionpro-bookoblique-webfont",
    "normal",
    "italic"
  )};
  ${fontFace(
    "FunctionPro",
    "functionpro/functionpro-lightoblique-webfont",
    "300",
    "italic"
  )};
  ${fontFace(
    "FunctionPro",
    "functionpro/functionpro-light-webfont",
    "300",
    "normal"
  )};
  ${fontFace(
    "FunctionPro",
    "functionpro/functionpro-medium-webfont",
    "700",
    "normal"
  )};
  ${fontFace(
    "FunctionPro",
    "functionpro/functionpro-book-webfont",
    "normal",
    "normal"
  )};
  ${(props) => normalize(props)};
  ${mediaStyle}
`
