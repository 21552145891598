import StoryModal from "@jewlr/storybook/core/modal"
import { goBack, replace } from "connected-react-router"
import PropTypes from "prop-types"
import React, { useEffect, useLayoutEffect, useState } from "react"
import { connect } from "react-redux"

import { history } from "app-store-v2/index"

export const closeModal = () => {
  const action = history.action === "PUSH" ? history.goBack : history.replace
  action(`${history.location.pathname}${history.location.search}`)
}

export const openModal = (hash) => {
  history.push(`${history.location.pathname}${history.location.search}${hash}`)
}

/**
  NOTE!!! Only use this modal if we're using the router hash to open/close the modal,
  Otherwise import Modal directly from storybook.

  @param {string} show - router hash used to open/close modal
*/

const InternalModal = ({
  afterShow,
  children,
  hideModal,
  routerHash,
  setModalContent,
  show,
  ...props
}) => {
  const [openModal, toggleModal] = useState(false)

  const hashMatched = Array.isArray(show)
    ? show.includes(routerHash)
    : routerHash === show

  useEffect(() => {
    toggleModal(hashMatched)
    if (hashMatched) {
      setModalContent && setModalContent(routerHash)
      afterShow && afterShow()
    }
  }, [routerHash])

  if (typeof window !== "undefined") {
    useLayoutEffect(() => {
      if (hashMatched) {
        hideModal()
      }
    }, [])
  }

  return (
    <StoryModal onClose={() => hideModal()} show={openModal} {...props}>
      {children}
    </StoryModal>
  )
}

const mapStateToProps = (state) => {
  return {
    lastRouterAction: state.router.action,
    routerHash: state.router.location.hash,
  }
}

const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

InternalModal.propTypes = {
  afterShow: PropTypes.func,
  children: PropTypes.node,
  hideModal: PropTypes.func,
  lastRouterAction: PropTypes.string,
  routerHash: PropTypes.string,
  setModalContent: PropTypes.func,
  show: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps
  const { lastRouterAction } = stateProps
  const routerAction = lastRouterAction == "PUSH" ? goBack : replace

  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    hideModal: () => {
      dispatch(
        routerAction(`${history.location.pathname}${history.location.search}`)
      )
    },
  }
}

const Modal = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(InternalModal)

export default Modal
