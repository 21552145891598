import { Box, Button, Flex, MediaBox, Typography } from "@jewlr/storybook/core"
import loadable from "@loadable/component"
import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import styled from "styled-components"

import IntersectionWrapper from "components/common/intersection-wrapper"
import { IS_CA, IS_US, IS_UK, IS_AU, retry } from "helpers/application"
import useWindowDimensions from "helpers/window-dimensions"

const FooterBottom = loadable(() => retry(() => import("./footer-bottom")))
const FooterContent = loadable(() => retry(() => import("./footer-content")))

const SubscribeWrapper = styled(Flex).attrs({
  contain: true,
  display: "block",
})`
  align-items: center;
  margin: 0 auto;
  ${(props) => props.theme.mediaQueries.desktop`
    width: 35%
  `}
`

const InternalFooterMain = ({ location }) => {
  const is_ca = IS_CA()
  const is_us = IS_US()
  const is_uk = IS_UK()
  const is_au = IS_AU()

  const { isMobile } = useWindowDimensions()

  const triggerPopup = () => {
    console.log("Popup trigger")
    try {
      if (isMobile) {
        //call the mobile Digioh
        if (is_us) {
          console.log("attentive mobile us: 383401")
          window.__attentive.trigger(null, null, null, 383401)
        } else if (is_ca) {
          console.log("attentive mobile us: 289587")
          window.__attentive.trigger(null, null, null, 289587)
        } else if (is_uk || is_au) {
          console.log("Digiog mobile UK&AU : 250908")
          window.DIGIOH_API.LIGHTBOX.loadLightbox(
            "324465c8-d1ea-4967-adb1-b3c23e80cfd2"
          )
        }
      } else {
        //call the desktop Digioh
        if (is_us) {
          console.log("Digioh desktop us: 261001")
          window.DIGIOH_API.LIGHTBOX.loadLightbox(
            "29e224ea-4754-4f9d-ac44-92d2208d0e70"
          )
        } else if (is_ca) {
          console.log("Digioh desktop is_ca: 256760")
          window.DIGIOH_API.LIGHTBOX.loadLightbox(
            "30e288cf-7f80-41a9-b93a-d708cbc20cae"
          )
        } else if (is_uk || is_au) {
          console.log("Digiog desktop UK&AU : 250907")
          window.DIGIOH_API.LIGHTBOX.loadLightbox(
            "449283ef-e8e8-41b3-acd9-b21aac38ea54"
          )
        }
      }
    } catch (err) {
      console.log("Error loading popup: ", err)
    }
  }

  if (
    location.pathname.includes("/checkout") ||
    location.pathname.includes("/payments")
  ) {
    return ""
  }
  return (
    <IntersectionWrapper id="footer-wrapper">
      <Box
        bg="grey.20"
        mt={{ tablet: 4 }}
        px={2}
        py={{ _: 4, tablet: 5 }}
        textAlign="center"
      >
        <SubscribeWrapper>
          <Typography
            color="black"
            fontSize={{ _: "16px", tablet: "18px" }}
            mb={{ _: 1, desktop: 2 }}
          >
            <Box as="span">Subscribe to our emails and save 10%</Box>
          </Typography>
          <Box>
            {["", "_desktop"].map((format) => (
              <MediaBox
                key={format}
                {...(format === "_desktop"
                  ? { greaterThanOrEqual: "tablet" }
                  : { lessThan: "tablet" })}
              >
                <Button
                  as="button"
                  bg="white"
                  color="black"
                  id={`subscribe_newsletter${format}`}
                  mx="auto"
                  onClick={() => triggerPopup()}
                  width={{ _: "70%", desktop: "100%" }}
                >
                  Get Your Code!
                </Button>
              </MediaBox>
            ))}
          </Box>
        </SubscribeWrapper>
      </Box>
      <Box as="footer" bg="neutral" pb={3} pt={6}>
        <FooterContent />
        <FooterBottom />
      </Box>
    </IntersectionWrapper>
  )
}
const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = () => ({})

InternalFooterMain.propTypes = {
  location: PropTypes.object,
}

const FooterMain = connect(
  mapStateToProps,
  mapDispatchToProps
)(InternalFooterMain)

export default withRouter(FooterMain)
