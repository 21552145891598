import { Flex, Image } from "@jewlr/storybook/core"
import React from "react"

const MerchantLoading = () => {
  return (
    <Flex
      style={{
        justifyContent: "center",
        marginBottom: "150px",
        marginTop: "150px",
      }}
    >
      <Image
        alt="loading"
        data-cy="collection-loading-icon"
        src={require("images/layout/loadinglarge.gif")}
        width="150px"
      />
    </Flex>
  )
}

export default MerchantLoading
