import { put, select, all } from "redux-saga/effects"

import { checkMetalExists } from "helpers/product/product-filtering"

import { updateChoice } from "../actions"

import filterChoices from "./filter-choices"

export default function* rejectInvalidChoices() {
  const state = yield select()
  // TEMP: For Flip SKUs due to incorrect preloaded data in Redux for stone options
  const is_flip_sku = Object.keys(state.wizard.data).find((k) =>
    k.includes("face")
  )
  const include_img_options = Object.keys(state.wizard.data).find((k) =>
    k.includes("img")
  )
  const ssr = state.router.action == "POP"
  if (
    ssr &&
    !state.wizardUI.startedPersonalizing &&
    !is_flip_sku &&
    !include_img_options
  ) {
    return
  }
  let updates = []
  Object.keys(state.choices).forEach((key) => {
    const data = state.wizard.data[key]
    if (
      typeof data !== "undefined" &&
      data.options &&
      state.choices[key].value
    ) {
      const checkOpt = data.options.find(
        (opt) => opt.value == state.choices[key].value
      )
      if (!checkOpt && !data.is_looks) {
        updates.push(
          put(
            updateChoice(key, {
              default_value: state.choices[key].value,
              removed_default: true,
              value: "",
            })
          )
        )
      }
    }
  })

  if (
    typeof state.wizard.data.sz !== "undefined" &&
    state.wizard.data.sz.nameplate &&
    state.wizard.data.sz.options &&
    (!state.choices.sz ||
      !state.choices.sz.value ||
      state.wizard.data.sz.options.filter(
        (sz) => sz.value == state.choices.sz.value
      ).length == 0)
  ) {
    const opt = state.wizard.data.sz.options.filter(
      (sz) =>
        checkMetalExists(sz.filters.sku, state.choices.sku.value) &&
        sz.filters.sku
    )[0]
    if (opt) {
      yield put(updateChoice("sz", opt))
    }
  }
  yield all(updates)

  for (let key in state.choices) {
    yield* filterChoices(updateChoice(key, state.choices[key]), true)
  }
}
