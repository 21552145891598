import { replace } from "connected-react-router"
import Cookies from "js-cookie"
import { put, select } from "redux-saga/effects"

import api from "apis"
import { isTestMode } from "helpers/application"
import { getPaymentInfo } from "helpers/checkout/payment"
// import { getUsdPrice } from "helpers/number"

const getCJTrackingUrl = (order) => {
  //  Commission Junkie Conversion Pixel
  let cj_tracking_url = `https://www.emjcd.com/tags/c?containerTagId=21708&CID=1542595&TYPE=397754&OID=${order.number}`
  order.line_items
    .filter((li) => li.state !== "cancelled")
    .map((line_item, index) => {
      let item_index = index + 1
      cj_tracking_url += `&ITEM${item_index}=${
        line_item.style_code
      }&AMT${item_index}=${parseFloat(line_item.price).toFixed(
        2
      )}&QTY${item_index}=1`
    })

  cj_tracking_url += `&CURRENCY=${order.currency}`
  cj_tracking_url +=
    order.discount_adjustment && order.discount_adjustment.label
      ? `&COUPON=${order.discount_adjustment.label}`
      : ""
  if (order.cjevent) {
    cj_tracking_url += `&CJEVENT=${order.cjevent["value"]}`
  }
  return cj_tracking_url
}

export default function* doJavaScriptTracking(action) {
  console.log("doJavaScriptTracking start")
  const state = yield select()
  const order = action.order
  if (Cookies.get(order.number) !== undefined) {
    return
  }
  // See the bottom of this saga for more information
  if (window.location.href.includes("tr=complete")) {
    return
  }
  console.log("doJavaScriptTracking main")
  // GA Enhanced Ecommerce
  if (!isTestMode) {
    try {
      // Not sure what this was used for
      // const getExchangeRate = getUsdPrice()
      const addCoupon =
        order.discount_adjustment && order.discount_adjustment.label
      const orderLineItems = order.line_items.map((li, index) => ({
        index: index + 1,
        line_item_details: li.line_item_details
          .filter((item) => item.category !== "DESCRIPTION")
          .map((lid) => ({ [lid.name]: lid.value })),

        price: parseFloat(li.price).toFixed(2),
        style_code: li.style_code,
        title: li.title,
      }))

      const products = order.line_items.map((line_item) => ({
        brand: line_item.style_code_suffix ? line_item.style_code_suffix : null,
        category: line_item.collection_url,
        collection_style_code: line_item.collection_style_code || "",
        id: line_item.item_variant,
        name: line_item.style_code,
        position: line_item.cidx ? line_item.cidx : "",
        price: line_item.price,
        quantity: line_item.quantity,
        variant: line_item.stone_types,
      }))

      const warrantyPlans = order.warranty_plans
      const giftWrap = order.gift_wrap
      const bundles = order.bundles
      const greetingCard = order.greeting_card

      let payment = {}
      if (Array.isArray(order.payments) && order.payments.length > 0) {
        const payments = order.payments.filter((payment) => payment.completed)
        if (payments.length) {
          payment = payments[0]
        }
      }

      // Not sure what this was used for
      // let payment_source = "other"
      // if (payment) {
      //   if (payment.payment_method == "creditcard") {
      //     switch (payment.cc_type) {
      //       case "visa":
      //         payment_source = "card_visa"
      //         break
      //       case "master":
      //         payment_source = "card_mastercard"
      //         break
      //       case "american express":
      //         payment_source = "card_amex"
      //         break
      //       case "discover":
      //         payment_source = "card_discover"
      //         break
      //       default:
      //         payment_source = "card_other"
      //     }
      //   } else {
      //     payment_source = payment.payment_method
      //   }
      // }

      const externalIds = []
      if (order.hashed_token) externalIds.push(order.hashed_token)
      if (order.user?.hashed_user_guid)
        externalIds.push(order.user.hashed_user_guid)

      dataLayer.push({
        // GA Enhanced Ecommerce Params
        ecommerce: {
          currencyCode: order.currency,
          purchase: {
            actionField: {
              affiliation: order.currency,
              coupon: addCoupon ? order.discount_adjustment.label : "",
              id: order.number,
              revenue: parseFloat(order.totals.order_total).toFixed(2),
              shipping: parseFloat(order.totals.shipping).toFixed(2),
              tax: parseFloat(order.tax_rate).toFixed(2),
            },
            products: products,
          },
        },
        email: order.customer.email,
        event: "order-completed",

        //  Deprecated: facebookContentIds will be replaced by orderLineItemStyleCodes
        facebookContentIds: order.line_items.map((li) => li.style_code),

        // GA4 Variable
        ga4Items: order.line_items.map((line_item, index) => ({
          coupon: addCoupon ? order.discount_adjustment.label : "",
          currency: order.currency,
          index: index,
          item_brand: order.merchant_name,
          item_category: line_item.product_category,
          item_id: line_item.item_variant,
          item_list_name: line_item.collection_url,
          item_name: line_item.actual_style_code,
          item_variant: line_item.sku,
          price: parseFloat(line_item.price).toFixed(2),
          quantity: line_item.quantity,
          shipping: parseFloat(order.totals.shipping).toFixed(2),
          tax: parseFloat(order.tax_rate).toFixed(2),
        })),
        // END GA4 Variable

        //# GA Event Params
        gaEventAction: "CheckoutMethod",
        gaEventCategory: "Checkout",
        gaEventLabel: order.checkout_type,
        gaEventNonInteraction: true,
        gaEventValue: order.totals.before_taxes,

        hashedEmail: order.customer.hashed_email,
        hashedPhone: order.customer.hashed_phone,
        hashedUserGUID: externalIds,

        orderBoxPrint: order.box_print,
        orderCoupon: addCoupon ? order.discount_adjustment.label : "",
        orderCouponAmount: addCoupon ? order.totals.discount + "" : "0",

        orderCurrencyCode: order.currency,
        orderGiftWrap: giftWrap,
        orderGreetingCard: greetingCard,
        orderLineItemStyleCodes: order.line_items.map((li) => li.style_code),
        orderLineItemTotal: order.line_item_total,
        orderLineItems: orderLineItems,
        orderMerchant: order.merchant_name,
        orderMerchantCountry: `${order.merchant_name} - ${order.site_country}`,
        orderNumber: order.number,
        orderPaymentSource: getPaymentInfo(order),
        orderPremiumGift: bundles,
        orderQuantity: order.line_items.length,
        orderShippingTotal: parseFloat(order.totals.shipping).toFixed(2),
        orderSubtotalBeforeDiscounts: order.totals.subtotal_before_discounts,
        orderTaxesTotal: parseFloat(order.tax_rate).toFixed(2),
        orderToken: order.token,
        orderTotal: order.totals.order_total,
        orderTotalBeforeShippingTaxes: order.totals.items_subtotal,
        orderTransactionId: payment.pp_reference_id || payment.transaction_id,
        orderWarrantyPlans: warrantyPlans,
        userPhone: order.customer.phone,
      })
    } catch (e) {
      console.log("GA Tracking Error: ", e)
    }
    console.log("doJavaScriptTracking dataLayer push")

    if (sl.config.enable_post_purchase_survey) {
      try {
        // Launch Digioh Post Purchase Survey
        // Only fire for Jewlr US orders and for orders that have not had survey filled in already
        const pps_order = order.custom_properties.post_purchase_survey
        if (pps_order === undefined) {
          window.digioh_pp_survey = {
            email: order.customer.email,
            order_number: order.number,
            prompt_agegender: order.customer.prompt_agegender,
            trigger_popup: true, // we don't need this one, but keep it for now to not break anything
          }
          dataLayer.push({
            event: "load-post-purchase-survey-inline",
          })
        }
      } catch (e) {
        console.log("Launch Post Purchase Survey error: ", e)
      }
    }
  }

  // Production Only Tags
  if (sl.config.env === "production" || sl.config.test_production_tags) {
    // Google Analytics
    if (sl.config.google_analytics_id) {
      try {
        api
          .get(
            `https://www.google-analytics.com/${
              sl.config.env === "production" ? "" : "debug"
            }/collect?v=1&tid=${sl.config.google_analytics_id}&cid=555`
          )
          .then(() => {
            // do nothing
          })
          .catch(() => {
            console.log("reporting to /checkout/bga")
            api
              .put("/secure/api/v10/checkout/bga", { order_token: order.token })
              .then(() => {
                console.log("reported to /checkout/bga")
              })
              .catch((err) => {
                console.log("Failed to report to /checkout/bga", err)
              })
          })
      } catch (e) {
        console.log("GA Tracking error: ", e)
      }
    }

    // Google Remarketing
    if (sl.config.google_remarketing_conversion_id && !isTestMode) {
      try {
        let google_remarketing_prodid = order.line_items
          ? order.line_items.map((li) => li.style_code)
          : null
        if (
          google_remarketing_prodid &&
          google_remarketing_prodid.length === 1
        ) {
          google_remarketing_prodid = google_remarketing_prodid[0]
        }
        dataLayer.push({
          event: "google-remarketing",
          googleRemarketing: {
            ecomm_pagetype: "purchase",
            ecomm_prodid: google_remarketing_prodid,
            ecomm_totalvalue: order.line_item_total,
          },
        })
      } catch (e) {
        console.log("Google Remarketing Error: ", e)
      }
    }

    // CommissionJunkie
    try {
      const cjIframe = document.createElement("iframe")
      cjIframe.setAttribute("frameborder", "0")
      cjIframe.setAttribute("scrolling", "no")
      cjIframe.setAttribute("name", "cj_conversion")
      cjIframe.setAttribute("src", getCJTrackingUrl(order))
      cjIframe.style.width = "1px"
      cjIframe.style.height = "1px"
      document.getElementsByTagName("body")[0].appendChild(cjIframe)
    } catch (e) {
      console.log("CJ Error", e)
    }
  }

  // Set order cookie
  Cookies.set(order.number, true, { expires: 365, secure: true })

  // Allow email edit for 5 mins
  Cookies.set("allow_email_edit", true, {
    expires: new Date(new Date().getTime() + 5 * 60 * 1000),
    secure: true,
  })

  console.log("doJavaScriptTracking end")

  // Extra safeguard to prevent subsequent javascript events if the browser
  // reloads the page from its own cache rather than from the origin server
  let newQuery = state.router.location.search
  if (newQuery.includes("?")) {
    newQuery = `${newQuery}&tr=complete`
    window.scrollTo(0, 0)
  } else {
    newQuery = "?tr=complete"
  }
  yield put(replace(`${state.router.location.pathname}${newQuery}`))
}
