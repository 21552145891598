import { faChevronDown } from "@jewlr/pro-regular-svg-icons/faChevronDown"
import { FontAwesomeIcon } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import { setDesktopSubmenu, activateSubmenu } from "app-store-v2/app/actions"
import useNavigation from "helpers/use-navigation"

import { NavButton, NavItem } from "./components"

const InternalMenuItem = ({
  index,
  item,
  showSubmenu,
  submenu,
  updateSubmenu,
}) => {
  const isOpened = showSubmenu && item.name === submenu.name
  const keyboardProps = useNavigation({ direction: "horizontal" })

  return (
    <NavItem key={index} role="none" sale={item.type === "link"}>
      <NavButton
        $isOpened={isOpened}
        data-cy={
          item.type === "dropdown"
            ? `header-menu-top-level-${item.name}-desktop`
            : `desktop_sale${index}_${item.name}`
        }
        id={item.name}
        onClick={() => {
          if (item.type === "dropdown") {
            updateSubmenu(item)
          }
        }}
        onMouseEnter={() => updateSubmenu(item.type === "dropdown" ? item : "")}
        role="menuitem"
        {...(item.type === "dropdown"
          ? {
              "aria-controls": "nav_dropdown_menu",
              "aria-expanded": isOpened,
              "aria-haspopup": true,
            }
          : {
              as: Link,
              color: "inherit",
              "data-lc": "menu",
              to: item.link,
            })}
        tabIndex={
          (showSubmenu && submenu.name ? isOpened : index === 0) ? 0 : -1
        }
        {...item.style}
        {...keyboardProps}
      >
        {item.name}
        {item.type === "dropdown" && (
          <FontAwesomeIcon
            color={isOpened ? "primary" : "grey.55"}
            fontSize="8px"
            icon={faChevronDown}
            ml="4px"
          />
        )}
      </NavButton>
    </NavItem>
  )
}

InternalMenuItem.propTypes = {
  index: PropTypes.number,
  item: PropTypes.object,
  showSubmenu: PropTypes.bool,
  submenu: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  updateSubmenu: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
    showSubmenu: state.menu.showSubmenu,
    submenu: state.menu.desktopSubmenu,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateSubmenu: (cat) => {
      dispatch(activateSubmenu())
      dispatch(setDesktopSubmenu(cat))
    },
  }
}

const MenuItem = connect(mapStateToProps, mapDispatchToProps)(InternalMenuItem)

export default MenuItem
